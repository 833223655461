export interface RaceLivepage {
    date: string;
    description: string;
    race_id: string;
}

export interface RaceInfo {
    date: string;
    organizer: string;
    description: string;
    accuracy: number;
    tags?: string[];
}

// export interface MyWebSocketType {
//     DataReceived?(data: any): void
// }

export interface NewMyWebSocketType {
    DataReceived?(collection: string, data: any, fullReload: boolean, idFieldsFromManifest: string[]): void
    Close(): void
}

export interface ShowPlaceAndTime {
    show_place: boolean;
    show_time: boolean;
    is_pursuit: boolean;
    penalty?: number;
    free_start?: boolean;
}

export interface Class extends ShowPlaceAndTime {
    num_legs: number;
    class_id: string;
    is_relay: boolean;
    sort_order: number;
    name: string;
    deleted: boolean;
    is_pursuit: boolean;
    num_shootings?: number;
    sprint_current_round_number?: number;
    sprint_total_number_of_rounds?: number;
    parent_class_id?: string;
    first_start?: string;
    free_start?: boolean;
}

export interface IntermediatePoint {
    code: string;
    class_id: string;
    text: string;
    sort_order: number;
    leg_number?: number;
}

export interface LiveEvent {
    first_date: string;
    last_date: string;
    description: string;
    event_id: string;
    organizer: string;
    public: boolean;
    race_type: string;
    is_today_marker: boolean;
}

export interface Time {
    place: number;
    used_time: number;
    used_time_leg: number;
    difference: number;
    passing_time: string;
    running_time?: number;
    running_place?: number;
    running_difference?: number;
}

// export interface Changes<Type> {
//     add: Type[];
//     update: Type[];
//     delete: Type[];
// }

export interface NewChanges<Type> {
    upsert: Type[];
    delete: any[][];
    // add: Type[];
    // update: Type[];
    // delete: Type[];
}

export interface Message {
    message: string;
    time_of_message: string;
    message_id: string
}

export interface Runner {
    times: { [index: string]: Time };
    status: RunnerStatus;
    given_name: string;
    family_name: string;
    class_id: string;
    deleted: boolean;
    runner_id: number;
    leg_number: number;
    bib: number;
    relay_team: string;
    club_name: string;
    favourite: boolean;
    starttime: string;
    time_previous_race: number;
    shootings?: string;
    timing_chips: number[];
    percentage?: number;
    estimated_starttime?: string;
    not_competing?: boolean;
}

export interface Webpage {
    description: string;
    url: string;
}

export interface Livecenter {
    event_description: string;
    organizer_url: string;
    pm_url: string;
    video_url: string;
    audio_url: string;
    startlists: Webpage[];
    resultlists: Webpage[];
    gps: Webpage[];
    external: Webpage[];
}

export interface RelayTeamsStarttime {
    [key: number]: string;
}

export interface Favourite {
    [runner_id: number]: boolean;
}

export interface PageSettings {
    favourites: Favourite;
}

export enum RunnerStatus {
    OK = 1,
    REGISTERED = 2,
    DSQ = 3,
    DNF = 4,
    DNS = 5,
    NC = 6
}


export interface PlaceAndTime {
    place: number,
    time: number
}

export interface ResultLookup {
    classes: {
        [key: string]: { // class id
            legs: {
                [key: number]: { // leg number
                    intermediate_points: {
                        [key: string]: PlaceAndTime[] // intermediate code
                    }
                }
            }
            show_place: boolean,
            show_time: boolean,
        }

    }
}

export const START_CODE: string = 'start';
export const FINISH_CODE: string = 'finish';
export const COMBINED_CODE: string = 'combined';

export interface Language {
    index_race_description: string,
    index_race_type: string,
    index_race_organizer: string,
    index_race_date: string,
    index_race_maintained_by: string,
    index_buy_liveresults: string,
    index_buy_liveresults_header: string,
    index_buy_liveresults_line1: string,
    index_buy_liveresults_listitem1: string,
    index_buy_liveresults_listitem2: string,
    index_buy_liveresults_listitem3: string,
    index_buy_liveresults_listitem4: string,
    index_buy_liveresults_listitem5: string,
    index_buy_liveresults_listitem6: string,
    index_buy_liveresults_contact: string,
    code: string,
    livecenter_heading: string,
    livecenter_organizer_homepage: string,
    livecenter_pm: string,
    livecenter_video_stream: string,
    livecenter_audio_not_supported: string,
    livecenter_audio_to_be_started: string,
    livecenter_audio_autostart: string,
    livecenter_start_audio: string,
    livecenter_audio_stopped: string,
    livecenter_audio_player_heading: string,
    livecenter_audio_player_subheading: string,
    livecenter_official_lists_subheading: string,
    livecenter_official_lists_heading: string,
    livecenter_gps_heading: string,
    livecenter_external_pages_heading: string,
    livecenter_stop_audio: string,
    livecenter_audio_could_not_load: string,
    livecenter_audio_loading: string,
    livecenter_audio_loaded: string,
    livecenter_live_results_subheading: string,
    livecenter_live_results_heading: string,
    race_race_info_header: string,
    race_description: string,
    race_organizer: string,
    race_date: string,
    race_messages: string,
    race_legs: string,
    race_classes: string,
    race_clubs: string,
    race_favourites: string,
    race_place: string,
    race_bib: string,
    race_leg: string,
    race_team: string,
    race_name: string,
    race_class_name: string,
    race_club_name: string,
    race_starttime: string,
    race_chip_number: string,
    race_used_time: string,
    race_difference: string,
    race_used_time_leg: string,
    race_used_time_leg_ridderuka: string,
    race_shootings: string,
    race_export_excel: string,
    race_first_page: string,
    race_last_page: string,
    race_no_runners: string,
    race_add_favourites: string,
    race_all_classes: string,
    race_sprint_prolog_suffix: string,
    race_sprint_finals_suffix: string,
    race_all_classes_table_heading: string,
    race_unknown_class_table_heading: string,
    race_favourites_table_heading: string,
    race_all_legs_table_heading: string,
    race_leg_table_heading: string,
    race_startlist_table_heading: string,
    race_finish_table_heading: string,
    race_combined_list_table_heading: string,
    race_intermediate_code_table_heading: string,
    race_stats_one_runner: string,
    race_stats_multiple_runners: string,
    race_stats_runners_remaining: string,
    race_all_legs: string,
    race_startlist: string,
    race_finish: string,
    race_combined_list: string,
    race_show_all_runners: string,
    free_start: string,
    no_event_today: string,
    num_runners_front_label: string,
    num_runners_back_label: string
}
