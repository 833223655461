import { GetClientId, RandomString } from "./clientid";

const PING_INTERVAL: number = 60 * 1000; // Every minute

const clientId = GetClientId();
const sessionId = RandomString(10);

export function StartPingTimer(page: string, extraData: () => {}): void {
    const url = 'https://erik.froseth.org/ping2.php';
   
    const doPing = function() {
        const data = {
            'viewer_id': clientId,
            'page': page,
            'referer': document.referrer,
            'session_id': sessionId,
            'extra_data': extraData()
        };
        
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open("POST", url);
        xmlHttp.send(JSON.stringify(data));
    };

    doPing();
    setInterval(doPing, PING_INTERVAL);
}