import { Language } from './types'
import { ModifyQueryParameter } from './utilities';

export function GetLanguage(language_code: string): Language {
    switch (language_code.toLowerCase()) {
        case 'en':
        case 'en-us':
        case 'en-gb':
            return en_US;
        default:
            return nb_NO;
    }
}

export function UpdateLanguage(language: string, initialPageLoad: boolean): Language {
    const newLanguage = GetLanguage(language);
    if (!initialPageLoad) {
        StoreSelectedLanguage(newLanguage.code);
        if (window.history) {
            window.history.replaceState(null, null, ModifyQueryParameter(location.href, 'lang', newLanguage.code));
        }
    }

    for (const element of document.querySelectorAll('[data-i18n]')) {
        const key = (element as any).dataset.i18n;
        element.innerHTML = newLanguage[key] as string;
    }

    return newLanguage;
}

export function GetInitialLanguage(): Language {
    // The priority is as follows:
    // 1) URL query parameter: ....&lang=en-us
    // 2) Local storage: key "language"
    // 3) Browser setting: navigator.language
    // 4) nb-NO: We assume most users are norwegian

    const urlParams = new URLSearchParams(window.location.search)
    const languageUrl: string = urlParams.get('lang');
    if (languageUrl !== null) {
        return GetLanguage(languageUrl);
    }

    let languageLocalStorage = localStorage.getItem('lang');
    if (languageLocalStorage !== null) {
        return GetLanguage(languageLocalStorage);
    }

    return GetLanguage(navigator.language);
}

export function StoreSelectedLanguage(language: string): void {
    localStorage.setItem('lang', language);
}

const nb_NO: Language = {
    code: 'nb-NO',
    index_race_description: 'Arrangement',
    index_race_type: 'Idrett',
    index_race_organizer: 'Arrangør',
    index_race_date: 'Dato',
    index_race_maintained_by: 'Driftet av',
    index_buy_liveresults: 'Liveresultater.no for deg eller din klubb? Klikk her for mer info',
    index_buy_liveresults_header: 'Få tilgang til liveresultater.no',
    index_buy_liveresults_line1: 'Nå kan du eller din klubb kjøpe årsabonnement på liveresultater.no! For 500,- eks. mva. per år vil du få:',
    index_buy_liveresults_listitem1: 'Tilgang til ubegrenset antall arrangement',
    index_buy_liveresults_listitem2: 'Lagring av lister og dokumenter direkte i livesenteret',
    index_buy_liveresults_listitem3: 'Tilgang til vårt brikkesjekk-system',
    index_buy_liveresults_listitem4: 'Støtte for eTiming (SQL/Access) og Brikkesys',
    index_buy_liveresults_listitem5: 'Tilgang til streaming av arenalyd',
    index_buy_liveresults_listitem6: 'Og enda mer',
    index_buy_liveresults_contact: 'For mer informasjon, ta kontakt på epost',
    livecenter_heading: 'Livesenter',
    livecenter_organizer_homepage: 'Arrangørens hjemmeside',
    livecenter_pm: 'PM',
    livecenter_video_stream: 'Videostreaming',
    livecenter_audio_not_supported: 'Nettleseren din støtter ikke lydavspilling',
    livecenter_audio_to_be_started: 'Arenalyd er annonsert, og vil bli tilgjengelig når lydstreamen er startet',
    livecenter_audio_autostart: 'Start arenalyd automatisk når den blir tilgjengelig',
    livecenter_start_audio: 'Start arenalyd',
    livecenter_stop_audio: 'Stopp arenalyd',
    livecenter_audio_stopped: 'Arenalyd er stoppet',
    livecenter_audio_player_heading: 'Arenalyd',
    livecenter_audio_player_subheading: '(Oppdater siden hvis lyden stopper underveis)',
    livecenter_official_lists_heading: 'Offisielle lister',
    livecenter_official_lists_subheading: 'Lister kommer så snart de er klare',
    livecenter_gps_heading: 'GPS-tracking',
    livecenter_external_pages_heading: 'Eksterne linker',
    livecenter_audio_could_not_load: 'Klarer ikke å laste arenalyd',
    livecenter_audio_loading: 'Prøver å laste arenalyd',
    livecenter_audio_loaded: 'Arenalyd spiller av',
    livecenter_live_results_subheading: '(alle liveresultater er uoffisielle)',
    livecenter_live_results_heading: 'Live Resultater',
    race_race_info_header: 'Løpsinformasjon',
    race_description: 'Arrangement',
    race_organizer: 'Arrangør',
    race_date: 'Dato',
    race_messages: 'Meldinger',
    race_legs: 'Etapper',
    race_classes: 'Klasser',
    race_clubs: 'Klubber',
    race_favourites: 'Favoritter',
    race_place: 'Plass',
    race_bib: 'St.nr',
    race_leg: 'Etp.',
    race_team: 'Lag',
    race_name: 'Navn',
    race_class_name: 'Klasse',
    race_club_name: 'Klubb',
    race_starttime: 'Starttid',
    race_chip_number: 'Brikkenr.',
    race_used_time: 'Tid',
    race_difference: 'Diff.',
    race_used_time_leg: 'Etappetid',
    race_used_time_leg_ridderuka: 'Bruttotid',
    race_shootings: 'Bom',
    race_export_excel: 'Eksport excel',
    race_first_page: 'Første',
    race_last_page: 'Siste',
    race_no_runners: 'Ingen løpere',
    race_add_favourites: 'Legg til løpere ved å klikke på løperens navn',
    race_all_classes: 'Alle klasser',
    race_sprint_prolog_suffix: 'prolog',
    race_sprint_finals_suffix: 'finaler',
    race_all_classes_table_heading: 'Alle klasser',
    race_unknown_class_table_heading: 'Ukjent',
    race_favourites_table_heading: 'Favoritter',
    race_all_legs_table_heading: 'alle etapper',
    race_leg_table_heading: 'etappe',
    race_startlist_table_heading: 'startliste',
    race_finish_table_heading: 'mål',
    race_combined_list_table_heading: 'kombinert liste',
    race_intermediate_code_table_heading: 'kode',
    race_stats_one_runner: '1 løper',
    race_stats_multiple_runners: 'løpere',
    race_stats_runners_remaining: 'gjenværende',
    race_all_legs: 'Alle',
    race_startlist: 'Startliste',
    race_finish: 'Mål',
    race_combined_list: 'Kombinert liste',
    race_show_all_runners: 'Alle',
    free_start: 'fristart',
    no_event_today: 'Ingen løp i dag',
    num_runners_front_label: 'Vis ',
    num_runners_back_label: ' løpere'
};


const en_US: Language = {
    code: 'en-US',
    index_race_description: 'Description',
    index_race_type: 'Sport',
    index_race_organizer: 'Organizer',
    index_race_date: 'Date',
    index_race_maintained_by: 'Maintained by',
    index_buy_liveresults: 'Liveresultater.no for you or your organisation? Click here for more information',
    index_buy_liveresults_header: 'Get access to liveresultater.no',
    index_buy_liveresults_line1: 'You or your organisation can now buy an annual subscription for liveresultater.no! For just 500 NOK excl. VAT. per year, you will get:',
    index_buy_liveresults_listitem1: 'Unlimited number of events',
    index_buy_liveresults_listitem2: 'Storage of lists and documents directly in our livecenter',
    index_buy_liveresults_listitem3: 'Access to our timing chip check system',
    index_buy_liveresults_listitem4: 'Support for eTiming (SQL/Access) and Brikkesys',
    index_buy_liveresults_listitem5: 'Access to audio streaming',
    index_buy_liveresults_listitem6: 'And more',
    index_buy_liveresults_contact: 'For more information, send an email to',

    livecenter_heading: 'Livecenter',
    livecenter_organizer_homepage: 'Organizer homepage',
    livecenter_pm: 'Event info',
    livecenter_video_stream: 'Video stream',
    livecenter_audio_not_supported: 'Your browser does not support audio streaming',
    livecenter_audio_to_be_started: 'Audio streaming is announced, and will be available once the stream has started',
    livecenter_audio_autostart: 'Start audio stream automatically once it\'s available',
    livecenter_start_audio: 'Start audio stream',
    livecenter_stop_audio: 'Stop audio stream',
    livecenter_audio_stopped: 'Audio stream is stopped',
    livecenter_audio_player_heading: 'Audio stream',
    livecenter_audio_player_subheading: '(Refresh the page if the audio stream stops)',
    livecenter_official_lists_heading: 'Official lists',
    livecenter_official_lists_subheading: 'Lists will be available when ready',
    livecenter_gps_heading: 'GPS tracking',
    livecenter_external_pages_heading: 'External pages',
    livecenter_audio_could_not_load: 'Could not load audio stream',
    livecenter_audio_loading: 'Loading audio stream',
    livecenter_audio_loaded: 'Audio stream is playing',
    livecenter_live_results_subheading: '(all live results are unofficial)',
    livecenter_live_results_heading: 'Live results',

    race_race_info_header: 'Race info',
    race_description: 'Description',
    race_organizer: 'Organizer',
    race_date: 'Date',
    race_messages: 'Messages',
    race_legs: 'Legs',
    race_classes: 'Classes',
    race_clubs: 'Clubs',
    race_favourites: 'Favourites',
    race_place: 'Place',
    race_bib: 'Bib',
    race_leg: 'Leg',
    race_team: 'Team',
    race_name: 'Name',
    race_class_name: 'Class',
    race_club_name: 'Club',
    race_starttime: 'Starttime',
    race_chip_number: 'Chip number',
    race_used_time: 'Time',
    race_difference: 'Diff.',
    race_used_time_leg: 'Leg time',
    race_used_time_leg_ridderuka: 'Gross time',
    race_shootings: 'Shootings',
    race_export_excel: 'Export excel',
    race_first_page: 'First',
    race_last_page: 'Last',
    race_no_runners: 'No runners',
    race_add_favourites: 'Add runners by clicking on their name',
    race_all_classes: 'All classes',
    race_sprint_prolog_suffix: 'prolog',
    race_sprint_finals_suffix: 'finals',
    race_all_classes_table_heading: 'All classes',
    race_unknown_class_table_heading: 'Unkown',
    race_favourites_table_heading: 'Favourites',
    race_all_legs_table_heading: 'all legs',
    race_leg_table_heading: 'leg',
    race_startlist_table_heading: 'startlist',
    race_finish_table_heading: 'finish',
    race_combined_list_table_heading: 'combined list',
    race_intermediate_code_table_heading: 'code',
    race_stats_one_runner: '1 runner',
    race_stats_multiple_runners: 'runners',
    race_stats_runners_remaining: 'remaining',
    race_all_legs: 'All',
    race_startlist: 'Startlist',
    race_finish: 'Finish',
    race_combined_list: 'Combined list',
    race_show_all_runners: 'All',
    free_start: 'free start',
    no_event_today: 'No event today',
    num_runners_front_label: 'Show ',
    num_runners_back_label: ' runners'
};
