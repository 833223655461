import { FINISH_CODE, IntermediatePoint, Runner } from "./types";

function AddBlankIntermediateTime(
    runner: Runner,
    code: string
): void {
    runner.times[code] = {
        difference: null,
        passing_time: null,
        place: null,
        running_difference: null,
        running_place: null,
        running_time: null,
        used_time: null,
        used_time_leg: null
    }
}

export function EnsureAllIntermediatePointsArePresent(
    runners: Runner[],
    allIntermediatePoints: IntermediatePoint[]
): void {
    let intermediatePointLookup: {
        [key: string]: { // class id
            [key: number]: { // leg number
                [key: string]: IntermediatePoint
            }
        }
    } = {};

    for (const intermediatePoint of allIntermediatePoints) {
        if (!(intermediatePoint.class_id in intermediatePointLookup)) {
            intermediatePointLookup[intermediatePoint.class_id] = {};
        }

        let legNumber = 1;
        if (intermediatePoint.leg_number !== null) {
            legNumber = intermediatePoint.leg_number;
        }

        if (!(legNumber in intermediatePointLookup[intermediatePoint.class_id])) {
            intermediatePointLookup[intermediatePoint.class_id][legNumber] = {};
        }

        intermediatePointLookup[intermediatePoint.class_id][legNumber][intermediatePoint.code] = intermediatePoint;
    }

    for (let runner of runners) {
        if (!(runner.class_id in intermediatePointLookup)) {
            intermediatePointLookup[runner.class_id] = {};
        }

        let legNumber = 1;
        if (runner.leg_number !== null) {
            legNumber = runner.leg_number;
        }
        if (!(legNumber in intermediatePointLookup[runner.class_id])) {
            intermediatePointLookup[runner.class_id][legNumber] = {};
        }

        // for (const code in runner.times) {
        //     if (code == FINISH_CODE) {
        //         continue;
        //     }

        //     if (!(code in intermediatePointLookup[runner.class_id][legNumber])) {
        //         delete runner.times[code];
        //     }
        // }

        for (const code in intermediatePointLookup[runner.class_id][legNumber]) {
            if (!(code in runner.times)) {
                AddBlankIntermediateTime(runner, code);
            }
        }

        if (!(FINISH_CODE in runner.times)) {
            AddBlankIntermediateTime(runner, FINISH_CODE);
        }
    }
}

export function ModifyQueryParameter(fullUrl: string, key: string, value: string): string {
    const idx = fullUrl.indexOf('?');
    if (idx == -1) {
        return fullUrl + '?' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }

    let params = new URLSearchParams(fullUrl.substring(idx));
    params.set(key, value);

    return fullUrl.substring(0, idx) + '?' + params.toString();
}

export function IsNullOrWhitespace(str: string): boolean {
    if (str === null) {
        return true;
    }

    for (const c of str) {
        if (c != ' ') {
            return false;
        }
    }

    return true;
}
