import { NewChanges, NewMyWebSocketType } from './types'

export interface DataPathsType {

  delta: string
  full: string
}

export interface PartType {
  type: string
  id: string[]
  data: DataPathsType[]
  last_modified: number
}

const ROOT_URL = 'https://storage.googleapis.com/liveresultater-public-files/';
const urlParams = new URLSearchParams(window.location.search)
const FORCED_INTERVAL_STR: string = urlParams.get('opdt');
let forcedInterval = Number.parseInt(FORCED_INTERVAL_STR);
if (!Number.isNaN(forcedInterval)) {
  // Ensure that we do not go below one second.
  forcedInterval = Math.max(1, forcedInterval);
}

export class NewWebsocket implements NewMyWebSocketType {
  private collectionId: string;
  private collectionsToTrigger: string[];
  private lastPartHandled: Map<string, DataPathsType> = new Map();
  private killed = false;

  public Close(): void {
    this.killed = true;
  }

  constructor(collectionId: string, collectionsToTrigger: string[]) {
    this.collectionId = collectionId;
    this.collectionsToTrigger = collectionsToTrigger;

    this.GetData()
  }

  private async ReadFullPart(collectionName: string, part: DataPathsType, idFieldsFromManifest: string[]): Promise<void> {
    const response = await fetch(ROOT_URL + this.collectionId + '/data/' + part.full);
    const json = await response.json();

    const change: NewChanges<any> = {
      upsert: json,
      delete: []
    }

    this.DataReceived(collectionName, change, true, idFieldsFromManifest);
  }

  private async AnalyzePart(collectionName: string, part: PartType): Promise<void> {
    if (part.data.length == 0) {
      return;
    }

    let lastPartHandled: DataPathsType = null;
    if (this.lastPartHandled.has(collectionName)) {
      lastPartHandled = this.lastPartHandled.get(collectionName);
    }

    const lastPartFromBackend = part.data[part.data.length - 1];
    if (lastPartHandled == null || (lastPartFromBackend.delta == '' && lastPartFromBackend.full != lastPartHandled.full)) {
      // Read the full data
      await this.ReadFullPart(collectionName, lastPartFromBackend, part.id);

    } else {
      // Find changes between last seen and the last from backend
      let needsFullReload = false;
      let add = false;
      let needToHandle: DataPathsType[] = [];
      for (let i = 0; i < part.data.length; ++i) {
        if (add) {
          needToHandle.push(part.data[i]);

          if (part.data[i].delta == '') {
            needsFullReload = true;
            break;
          }
        }

        if (part.data[i].full == lastPartHandled.full) {
          add = true;
        }
      }

      if (!add) {
        // We did not see our last handled file, so we need a full reload.
        needsFullReload = true;
      }

      // Are any of these full reload?
      if (needsFullReload) {
        await this.ReadFullPart(collectionName, lastPartFromBackend, part.id);
      } else {
        let promises: { index: number, promise: Promise<Response> }[] = [];
        for (let i = 0; i < needToHandle.length; ++i) {
          promises.push({
            index: i,
            promise: fetch(ROOT_URL + this.collectionId + '/data/' + needToHandle[i].delta)
          });
        }

        for (const row of promises) {
          const response = await row.promise;
          const json = await response.json();

          this.DataReceived(collectionName, json, false, part.id);
        }
      }
    }


    this.lastPartHandled.set(collectionName, lastPartFromBackend);
  }

  private async GetData() {
    let interval = 10000;
    try {
      const manifestFileName = Number.isNaN(forcedInterval) ? 'manifest' : 'manifest-nocache';
      const response = await fetch(ROOT_URL + this.collectionId + '/' + manifestFileName);
      const data = await response.json();
      
      let secondsSinceLastChange = Number.MAX_VALUE;
      let promises: Promise<any>[] = [];
      for (const key in data) {
        if (key == 'version') {
          continue;
        }
  
        let found = false;
        for (const val of this.collectionsToTrigger) {
          if (val == key) {
            found = true;
            break;
          }
        }
        
        if (!found) {
          continue;
        }
        
        const part = data[key] as PartType;
        const nowUnixSec = Date.now() / 1000;
        secondsSinceLastChange = Math.min(secondsSinceLastChange, (nowUnixSec - part.last_modified));
        promises.push(this.AnalyzePart(key,part));
      }
  
      await Promise.all(promises);
  
      if (secondsSinceLastChange < 60) {
        interval = 2000;
      } else if (secondsSinceLastChange < (10 * 60)) {
        interval = 5000;
      }
    } catch (ex) {
      // Silently accept errors so that setTimeout still gets called below.
    }

    if (!Number.isNaN(forcedInterval)) {
      interval = forcedInterval * 1000;
    }

    if (!this.killed) {
      setTimeout(this.GetData.bind(this), interval);
    }
  }


  public DataReceived?: (collection: string, data: any, fullReload: boolean, idFieldsFromManifest: string[]) => void
}
